import { HttpResponse } from "..";

export class BlogListModel extends HttpResponse {
  hasMoreData: boolean = false;
  items: BlogModel[] = [];

  fromJson(json: { data?: Array<Record<string, any>> }): BlogListModel {
    this.items = json["data"]
      ? json["data"].map((e: Record<string, any>) =>
          new BlogModel().fromJson(e)
        )
      : [];
    this.hasMoreData = this.items.length > 0;
    return this;
  }
}

export class BlogModel extends HttpResponse {
  id?: string;
  name?: string;
  thumbnail?: string;
  summary?: string;
  content?: string;
  createdAt?: Date;

  fromJson(json: Record<string, any>): BlogModel {
    this.id = json["id"];
    this.name = json["name"];
    this.thumbnail = json["thumbnail"];
    this.summary = json["summary"];
    this.content = json["content"];
    this.createdAt = json["createdAt"]
      ? new Date(json["createdAt"])
      : undefined;
    return this;
  }
}
