import { PageTitle } from "../../components/page-title/page-title";

const PycomSolutionsPrivacyPolicyPage = () => {
  return (
    <div id="dms-solution-page" className="main">
      <section id="dms-solution-page-zalo-mini-app">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex flex-column align-items-center mb-4"></div>
              <div className="section-heading text-center">
                <PageTitle title="Chính sách bảo mật" />
              </div>
              <div className="page-content">
                <h3>1. Mục đích và phạm vi thu thập thông tin</h3>
                <p className="font-weight-bolder">
                  Khi sử dụng dịch vụ của chúng tôi, bạn tin tưởng cung cấp
                  thông tin của bạn cho chúng tôi. Chúng tôi hiểu rằng đây là
                  một trách nhiệm lớn và chúng tôi nỗ lực bảo vệ thông tin của
                  bạn cũng như để bạn nắm quyền kiểm soát.
                </p>
                <p>
                  Chính sách bảo mật này nhằm mục đích giúp bạn hiểu rõ những
                  thông tin chúng tôi thu thập, lý do chúng tôi thu thập và cách
                  bạn có thể cập nhật, quản lý và xóa thông tin của mình.
                </p>
                <p>Dành cho người dùng ứng dụng Pycom TMS</p>
                <h3>2. Thu thập thông tin cá nhân</h3>
                <p>
                  Các thông tin cơ bản như: họ và tên, ảnh đại diện, số điện
                  thoại được chúng tôi yêu cầu bạn cung cấp khi đăng ký tài
                  khoản trên ứng dụng theo điều khoản sử dụng dịch vụ mà chúng
                  tôi đã cung cấp (xem thêm Điều khoản sử dụng).
                </p>
                <p>
                  Các thông tin trên được chúng tôi lưu trữ tạm thời cho mục
                  đích quản lí tài khoản người dùng, xác thực thông tin đăng
                  nhập, xác thực thông tin giao dịch khi bạn tham gia vận chuyển
                  hàng hóa.
                </p>
                <p>
                  Dữ liệu nêu trên của bạn sẽ không được cung cấp cho bất kỳ bên
                  thứ ba nào trừ những trường hợp đặc biệt ghi trong tài liệu
                  này.
                </p>
                <h3>3. Truy cập và sử dụng dữ liệu vị trí (GPS)</h3>
                <p>
                  Ứng dụng truy cập dữ liệu vị trí cho tính năng dẫn đường, cập
                  nhật vị trí chuyến hàng ngay cả khi bạn không sử dụng ứng dụng
                  hoặc đóng ứng dụng lại.
                </p>
                <p>
                  Truy cập dữ liệu vị trí chạy nền cho Ứng dụng Tài xế, nhằm mục
                  đích lấy vị trí tài xế tự động khi đang được giao xử lý một
                  chuyến hàng. Việc truy cập dữ liệu nền sẽ tự động tắt khi tài
                  xế tắt ứng dụng hoặc hạn chế cho phép truy cập dữ liệu nền.
                </p>
                <p>
                  Dữ liệu vị trí được sử dụng với những tính năng cụ thể sau:
                  tính năng dẫn đường trên bản đồ (được tích hợp bên trong ứng
                  dụng) giúp tài xế dễ dàng di chuyển đến điểm bốc và điểm dỡ
                  hàng, tính năng tìm kiếm đơn hàng gần nhất với vị trí của tài
                  xế (của bạn), tính năng hiển thị vị trí của tài xế cũng như
                  chuyến hàng trên bản đồ giúp nhóm điều phối vận chuyển của
                  Vantaisieutoc cũng như chủ hàng có thể theo dõi được hành
                  trình của hàng hóa.
                </p>
                <p>
                  Ứng dụng chỉ truy cập thông tin vị trí trong khi bạn đang thực
                  hiện chuyến hàng nhằm mục đích tự động hóa ghi nhận vị trí khi
                  bạn thực hiện hành động cập nhật trạng thái chuyến khi đến
                  điểm bốc hàng, dỡ hàng.
                </p>
                <p>
                  Dữ liệu vị trí của bạn sẽ không được cung cấp cho bất kỳ bên
                  thứ ba nào trừ những trường hợp đặc biệt ghi trong tài liệu
                  này
                </p>
                <h3>4. Địa chỉ đơn vị thu thập và quản lý thông tin</h3>
                <p>
                  Công ty TNHH Công nghệ PyCOM Solutions
                  <br />
                  Địa chỉ: Villa D6, Village An Phú Đông, An Phú Đông, Quận 12,
                  Hồ Chí Minh
                  <br />
                  Điện thoại: <a href="tel:0909293228">0909 293 228</a>
                  Email: <a href="mailto:info@pycom.vn">info@pycom.vn</a>
                </p>
                <h3>5. Phạm vi sử dụng thông tin</h3>
                <p>Việc thu thập thông tin nhằm mục đích:</p>
                <p>Cung cấp các dịch vụ đến Thành viên, người dùng;</p>
                <p>
                  Gửi các thông báo về các hoạt động trao đổi thông tin giữa
                  thành viên, người dùng và Pycom TMS
                </p>
                <p>
                  Ngăn ngừa các hoạt động phá hủy tài khoản người dùng của thành
                  viên hoặc các hoạt động giả mạo Thành viên;
                </p>
                <p>
                  Liên lạc và giải quyết với thành viên trong những trường hợp
                  đặc biệt.
                </p>
                <p>
                  Cung cấp vị trí tài xế khi đang vận hành chuyến xe nhắm mục
                  đích nhân viên điều vận nắm rõ vị trí để điều phối hiệu quả.
                </p>
                <p>
                  Không sử dụng thông tin cá nhân của thành viên ngoài mục đích
                  xác nhận và liên hệ có liên quan đến giao dịch tại Pycom
                  Solutions.
                </p>
                <p>
                  Trong trường hợp có yêu cầu của pháp luật: Pycom Solutions có
                  trách nhiệm hợp tác cung cấp thông tin cá nhân thành viên khi
                  có yêu cầu từ cơ quan tư pháp bao gồm: Viện kiểm sát, tòa án,
                  cơ quan công an điều tra liên quan đến hành vi vi phạm pháp
                  luật nào đó của khách hàng. Ngoài ra, không ai có quyền xâm
                  phạm vào thông tin cá nhân của thành viên.
                </p>
                <p>
                  Phương tiện và công cụ để người dùng tiếp cận và chỉnh sửa dữ
                  liệu cá nhân của mình
                </p>
                <p>
                  Thành viên có quyền tự kiểm tra, cập nhật, điều chỉnh hoặc hủy
                  bỏ thông tin cá nhân của mình bằng cách đăng nhập vào tài
                  khoản và chỉnh sửa thông tin cá nhân hoặc yêu cầu Pycom
                  Solutions thực hiện việc này. Khi tiếp nhận những phản hồi
                  này, pycom.vn sẽ xác nhận lại thông tin, phải có trách nhiệm
                  trả lời lý do và hướng dẫn thành viên khôi phục hoặc xóa bỏ
                  thông tin cá nhân.
                </p>
                <h3>6. Cam kết bảo mật thông tin</h3>
                <p>
                  Thông tin cá nhân của thành viên trên pycom.vn được Pycom TMS
                  cam kết bảo mật tuyệt đối theo chính sách bảo vệ thông tin cá
                  nhân của Vantaisieutoc và không cung cấp cho bên thứ 3, trừ
                  trường hợp có yêu cầu của pháp luật www.vantaisieutoc.vn có
                  trách nhiệm hợp tác cung cấp thông tin cá nhân thành viên khi
                  có yêu cầu từ cơ quan tư pháp bao gồm: Viện kiểm sát, tòa án,
                  cơ quan công an điều tra liên quan đến hành vi vi phạm pháp
                  luật nào đó của khách hàng. Ngoài ra, không ai có quyền xâm
                  phạm vào thông tin cá nhân của thành viên. Việc thu thập và sử
                  dụng thông tin của mỗi thành viên chỉ được thực hiện khi có sự
                  đồng ý của khách hàng đó trừ những trường hợp pháp luật có quy
                  định khác.
                </p>
                <h3>7. Thời gian lưu trữ thông tin</h3>
                <p>
                  Dữ liệu cá nhân của Thành viên sẽ được lưu trữ cho đến khi có
                  yêu cầu hủy bỏ hoặc tự thành viên đăng nhập và thực hiện hủy
                  bỏ. Còn lại trong mọi trường hợp thông tin cá nhân thành viên
                  sẽ được bảo mật trên máy chủ của{" "}
                  <a href="https://pycom.vn/">pycom.vn</a>.
                </p>
                <p>
                  Cơ chế tiếp nhận và giải quyết khiếu nại liên quan đến việc
                  thông tin cá nhân khách hàng
                </p>
                <p>
                  Thành viên có quyền gửi khiếu nại về việc lộ thông tin các
                  nhân cho bên thứ 3 đến Ban quản trị của{" "}
                  <a href="https://pycom.vn/">pycom.vn</a> đến địa chỉ Công ty
                  hoặc qua email{" "}
                  <a href="mailto:info@pycom.vn">info@pycom.vn</a>
                </p>
                <p>
                  Công ty có trách nhiệm thực hiện các biện pháp kỹ thuật,
                  nghiệp vụ để xác minh các nội dung được phản ánh.
                </p>
                <p>
                  Thời gian xứ lý phản ánh liên quan đến thông tin cá nhân khách
                  hàng là 15 ngày.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PycomSolutionsPrivacyPolicyPage;
