import { format, differenceInDays, subDays } from "date-fns";

export class MwaDateTimeUtil {
  static dateOnly = (dateRequest: Date): string => {
    return format(dateRequest, "dd/MM/yyyy");
  };
  static timeOnly = (dateRequest: Date): string => {
    return format(dateRequest, "dd/MM/yyyy");
  };
  static getWeekday = (dateRequest: Date): string => {
    switch (dateRequest.getDay()) {
      case 0:
        return "Chủ nhật";
      case 1:
        return "Thứ hai";
      case 2:
        return "Thứ ba";
      case 3:
        return "Thứ tư";
      case 4:
        return "Thứ năm";
      case 5:
        return "Thứ sáu";
      case 6:
        return "Thứ bảy";
      default:
        return "";
    }
  };

  static historyAgo = (dateRequest: Date): string => {
    if (!dateRequest) return "";

    const now = new Date();
    const difference = differenceInDays(now, dateRequest);

    if (dateRequest.getFullYear() !== now.getFullYear()) {
      return `${this.dateOnly(dateRequest)}, ${format(dateRequest, "HH:mm")}`;
    }

    if (dateRequest.getDate() === now.getDate()) {
      return `Hôm nay, ${format(dateRequest, "HH:mm")}`;
    } else if (
      (difference === 0 && dateRequest.getDate() !== now.getDate()) ||
      (difference === 1 && dateRequest.getDate() === subDays(now, 1).getDate())
    ) {
      return `Hôm qua, ${format(dateRequest, "HH:mm")}`;
    } else if (
      Math.floor(difference / 7) < 1 ||
      dateRequest.getDate() === subDays(now, 7).getDate()
    ) {
      return `${this.getWeekday(dateRequest)}, ${format(dateRequest, "HH:mm")}`;
    } else {
      return `${format(dateRequest, "dd/MM")}, ${format(dateRequest, "HH:mm")}`;
    }
  };
}
