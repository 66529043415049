import { FC, ImgHTMLAttributes } from "react";

interface MwaImageProps extends ImgHTMLAttributes<any> {
  fallbackSrc?: string;
}

export const MwaImage: FC<MwaImageProps> = ({ fallbackSrc, ...props }) => {
  return (
    <img
      src={props.src}
      alt={props.alt}
      onError={(e) => {
        const target = e.target as HTMLImageElement;
        target.onerror = null;
        target.src =
          fallbackSrc ??
          "https://static.vecteezy.com/system/resources/previews/005/337/799/non_2x/icon-image-not-found-free-vector.jpg";
      }}
      {...props}
    />
  );
};
