import {RegisterFormModal} from "../index";

interface BookADemoButtonProps {
  className?: string;
}

export const BookADemoButton: React.FC<BookADemoButtonProps> = ({ className }) => {
  return (
    <>
      <button
        className={`btn btn-primary btn-book-demo color-white ${className || ""}`}
        data-bs-toggle="modal"
        data-bs-target="#registerFormModal"
      >
        Đăng ký trải nghiệm
      </button>
    </>
  )
}
