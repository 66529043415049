import { atom } from "recoil";
import { BlogListModel } from "../../models";
import { SessionState } from "../../utils";

export const blogsState = atom<BlogListModel>({
  key: "blogsState",
  default: new BlogListModel(),
});

export const loadBlogsState = atom<SessionState>({
  key: "loadBlogsState",
  default: SessionState.idle,
});
